import { MatxLoading } from "app/components/MatxComponents";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { createContext, useEffect, useReducer } from "react";
import { JWTConfig } from "config";

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
};

const isValidToken = (accessToken) => {
  if (!accessToken) return false;

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, user, isAuthenticated, isInitialised: true };
    }
    case "LOGIN": {
      const { user } = action.payload;
      return { ...state, user, isAuthenticated: true };
    }
    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }
    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: (email, password) => {},
  login: (email, password) => Promise.resolve(),
  register: (email, password) => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password, remember) => {

    if (remember) {
      // If the "Remember Me" checkbox is checked, store the user's email in local storage
      localStorage.setItem('userEmail', email);
    } else {
      // If the "Remember Me" checkbox is not checked, remove the user's email from local storage
      localStorage.removeItem('userEmail');
    }
  
    try {
      const response = await axios({
        url: JWTConfig.api,
        method: 'post',
        data: {
          query: `
            mutation Login {
              login(username: "${email}", password: "${password}") {
                token
                user {
                  role
                  email
                  name
                  username
                  avatar
                }
              }
            }
          `
        }
      });
    
      if (response.data.errors) {
        const error = new Error(response.data.errors[0].message);
        console.error('Error logging in:', error); // Log any errors
        throw error;
      }
  
      const { token, user } = response.data.data.login;
      setSession(token); // Store the token in local storage
      localStorage.setItem('user', JSON.stringify(user));
      dispatch({ type: "LOGIN", payload: { user } }); // Update the user state

    } catch (error) {
      console.error('Error logging in:', error); // Log any errors
      throw error;
    }
  };
   
  
  const register = async (email, username, password) => {
    const { data } = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });

    const { accessToken, user } = data;
    setSession(accessToken);
    dispatch({ type: "REGISTER", payload: { user } });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const response = await axios.get("/api/auth/profile");
          const { user } = response.data;

          dispatch({
            type: "INIT",
            payload: { isAuthenticated: true, user },
          });
        } else {
          dispatch({
            type: "INIT",
            payload: { isAuthenticated: false, user: null },
          });
        }
      } catch (err) {
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
