import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import App from "./app/App";

// third party css
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, split } from '@apollo/client';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { JWTConfig } from "config";

import { onError } from "@apollo/client/link/error";
import { ApolloLink } from 'apollo-link';

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // If there is a network error, redirect to login page
    window.location.href = '/session/signin';
  }
});

// Create an http link:
const httpLink = new HttpLink({
  uri: JWTConfig.api,
  headers: {
    'Authorization': `${localStorage.getItem('accessToken')}`
  }
});

// Create a WebSocket link:
const wsLink = new GraphQLWsLink(
  createClient({
    url: JWTConfig.api.replace(/^http/, 'ws'), // Replace 'http' or 'https' with 'ws' or 'wss' respectively
    connectionParams: {
      'Authorization': `${localStorage.getItem('accessToken')}`
    }
  })
);

// Using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent.
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

// Instantiate client with error link
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, splitLink]),
  cache: new InMemoryCache()
});

// main root element
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <StyledEngineProvider injectFirst>
    <ApolloProvider client={client}>
    <BrowserRouter>
      <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "top" }}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
    </ApolloProvider>
  </StyledEngineProvider>
);
