import { lazy } from "react";
import Loadable from "app/components/MatxComponents/UtilComponents/Loadable";
import { authRoles } from "app/auth/authRoles";
import AuthGuard from "./auth/AuthGuard";
import Redirect from "./auth/Redirect";
import MatxLayout from "./components/MatxComponents/MatxLayout/MatxLayout";
import NotFound from "./views/sessions/NotFound";

const JwtRegister = Loadable(lazy(() => import("./views/sessions/register/JwtRegister")));
const JwtLogin = Loadable(lazy(() => import('./views/sessions/login/JwtLogin')));
const ForgotPassword = Loadable(lazy(() => import("./views/sessions/ForgotPassword")));

const Analytics2 = Loadable(lazy(() => import("./views/dashboard/Analytics2")));
const DriverTable = Loadable(lazy(() => import("./views/driver/DriverTable")));
const UserTable = Loadable(lazy(() => import("./views/user/UserTable")));
const TruckTable = Loadable(lazy(() => import("./views/truck/TruckTable")));
const SmsService = Loadable(lazy(() => import("./views/sms/SmsForm")));
const LeaseTable = Loadable(lazy(() => import("./views/lease/LeaseTable")));
const PointOfInterestTable = Loadable(lazy(() => import("./views/pointOfInterest/PointOfInterestTable")));
const ReportTable = Loadable(lazy(() => import("./views/report/ReportTable")));
const MessageTable = Loadable(lazy(() => import("./views/message/MessageTable")));
const JobTable = Loadable(lazy(() => import("./views/job/JobTable")));
const ClientTable = Loadable(lazy(() => import("./views/client/ClientTable")));
const AssignmentTable = Loadable(lazy(() => import("./views/assignment/AssignmentTable")));

const sessionRoutes = [
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },
  { path: "/session/404", element: <NotFound /> },
];

const allRoutes = [
  { path: "dashboard/default", element: <Analytics2 />, auth: authRoles.sa },
  { path: "/driver-table", element: <DriverTable /> },
  { path: "/user-table", element: <UserTable /> },
  { path: "/truck-table", element: <TruckTable /> },
  { path: "/sms-service", element: <SmsService /> },
  { path: "/lease-table", element: <LeaseTable /> },
  { path: "/point-of-interest-table", element: <PointOfInterestTable /> },
  { path: "/report-table", element: <ReportTable /> },
  { path: "/message-table", element: <MessageTable /> },
  { path: "/job-table", element: <JobTable /> },
  { path: "/client-table", element: <ClientTable /> },
  { path: "/assignment-table", element: <AssignmentTable /> },
];

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...allRoutes],
  },
  ...sessionRoutes,
  { path: "/", element: <Redirect /> },
  { path: "*", element: <NotFound /> },
];

export default routes;