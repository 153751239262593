// import { authRoles } from "app/auth/authRoles"; 

export const navigations = [
  // {
  //   name: "Dashboard",
  //   path: "/dashboard/default",
  //   icon: "dashboard",
  //   auth: authRoles.sa, // ONLY SUPER ADMIN(SA) CAN ACCESS
  // },
  { name: "Assignments", icon: "navigation", path: "/assignment-table" },
  // { name: "SMS", icon: "message", path: "/sms-service" },
  { name: "Drivers", icon: "airline_seat_recline_normal", path: "/driver-table" },
  { name: "Trucks", icon: "local_shipping", path: "/truck-table" },
  { name: "Clients", icon: "apartment", path: "/client-table" },
  { name: "Jobs", icon: "fact_check", path: "/job-table" },
  { name: "Leases", icon: "person_add", path: "/lease-table" },
  { name: "POIs", icon: "place", path: "/point-of-interest-table" },
  { name: "Reconciliation", icon: "text_snippet", path: "/report-table" },
  { name: "Messages", icon: "message", path: "/message-table" },
  { name: "Users", icon: "people", path: "/user-table" },
];
